<template>
  <div id="newData">
    <div class="titleHeader">
      <img src="~@ass/img/1.4.0.2/ico_jcbs备份 2@2x.png" alt="" />
      <p>
        请您遵守国家相关规定，切勿上传低俗色情、暴力恐怖、谣言诈骗、侵权盗版等相关内容，叮叮开课保有依据国家规定及平台规则进行处理的权利。
      </p>
    </div>
    <el-button class="copyBtn" size="medium" @click="toggleShowCopy" v-if="$route.params.id == 'new'">一键复制</el-button>
    <el-form
      @submit.native.prevent
      style="width: 871px"
      label-position="left"
      :model="ruleForm"
      ref="ruleForm"
      label-width="110px"
      class="demo-ruleForm"
    >
      <el-form-item label="直播名称" required>
        <el-input
          v-model="ruleForm.name"
          maxlength="50"
          show-word-limit
          placeholder="请输入名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="直播老师" required>
        <el-select
          :disabled="ruleForm.status == 1"
          filterable
          v-model="ruleForm.teacher_id"
          style="width: 100%"
          placeholder="请输入老师名称或选择上课老师"
        >
          <el-option
            v-for="item in teacherOptions"
            :key="item.uid"
            :label="item.uname"
            :value="item.uid"
          >
            <div
              style="
                float: left;
                height: 22px;
                width: 22px;
                border-radius: 50%;
                margin-top: 6px;
                background: grey;
              "
            >
              <img
                style="height: 22px; width: 22px"
                :src="item.uphoto"
                alt=""
              />
            </div>
            <div style="float: left; margin-left: 10px; font-size: 13px">
              {{ item.uname }}
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="助教老师">
        <div slot="label" class="flex-center" style="margin-left: 9px">
          助教老师
          <helpIcon
            style="margin-left: 6px"
            content="助教老师为网校内的角色，可以和直播老师一起管理直播间"
          ></helpIcon>
        </div>
        <el-select
          class="selectAllAst"
          clearable
          multiple
          :disabled="ruleForm.status == 1"
          filterable
          v-model="ruleForm.assistant_ids"
          style="width: 100%"
          placeholder="选填，请输入老师名称或选择老师"
          @change='changeAssistant'
        >
          <el-checkbox v-model="selectAllAssistant" @change='selectAll' style="padding: 0 20px">全选</el-checkbox>
          <el-option
            v-for="item in teacherOptions"
            :key="item.uid"
            :label="item.uname"
            :value="item.uid"
          >
            <div @click.stop>
              <el-checkbox style="float: left;margin-right: 10px;" :value ="ruleForm.assistant_ids.includes(item.uid)" @change="clickBox(item.uid)"></el-checkbox>
            </div>
            <div
              style="
                float: left;
                height: 22px;
                width: 22px;
                border-radius: 50%;
                margin-top: 6px;
                background: grey;
              "
            >
              <img
                style="height: 22px; width: 22px"
                :src="item.uphoto"
                alt=""
              />
            </div>
            <div style="float: left; margin-left: 10px; font-size: 13px">
              {{ item.uname }}
            </div>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="转为智能脚本"
      >
        <div slot="label" class="flex-center" style="margin-left: 9px">
          转为智能脚本
        </div>
        <el-switch
          v-model="ruleForm.template_switch"
          active-color="#1B9D97"
          inactive-color="#DCDFE6"
          active-value="1"
          inactive-value="2">
        </el-switch>
      </el-form-item>
      <el-form-item
        label="关联直播课"
      >
        <div slot="label" class="flex-center" style="margin-left: 9px">
          关联直播课
        </div>
        <div class="add_button" @click="networkschoolSelectStatus = true">
          + 选择直播课
        </div>
        <div class="select-contain">
          <div class="list">
            <el-row v-for="(item, index) in ruleForm.course_ids" :key="index">
              <el-col :span="16">
                <div class="flex-center">
                  <div class="tu">
                    <img
                      :src="
                        item.photo || item.cover_img || item.img_url
                      "
                      alt=""
                    />
                  </div>
                  <div class="name" :title="item.name">
                    {{ item.name }}
                  </div>
                </div>
              </el-col>
              <el-col :span="2">
                <el-popconfirm
                  @confirm="confirmCouseselect(index)"
                  title="是否确定移除该内容？"
                >
                  <el-button type="text" slot="reference">
                    移除
                  </el-button>
                </el-popconfirm>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form-item>

      <el-form-item
        label="直播时间"
        required
        v-if="fatherPath == '/publicClass'||fatherPath=='/intelligence'&&ruleForm.is_old!=1"
        >
        <!-- v-if="fatherPath == '/publicClass'" -->
        <div class="">
          <el-form-item>
            <el-date-picker
              format="yyyy-MM-dd HH:mm"
              :disabled="ruleForm.status == 1"
              style="width: 365px"
              type="datetime"
              :pickerOptions="pickerOptions"
              placeholder="选择日期"
              value-format="timestamp"
              v-model="ruleForm.start_time"
              @change="addMin"
            ></el-date-picker>
          </el-form-item>
        </div>
      </el-form-item>

      <!--智能脚本-->
      <el-form-item label="智能脚本" v-if="fatherPath=='/intelligence'&&ruleForm.is_old!=1" required>
        <div style="display: flex;">
          <YSelect
            style="width:340px;"
            v-model="ruleForm.msg_id" 
            :hasAll="false"
            :multiple="false"
            :options="options3" 
            :loading="loading2"
            :isFinished="isFinished2"
            :placeholder="'请选择智能脚本'"
            @visibleChange="visibleChange2" 
            @loadMore="loadMore2"
            @remoteMethod="remoteMethod2" 
            @change="change2"
          ></YSelect>
        </div>
      </el-form-item>
      
      <el-form-item label="公告">
        <div slot="label" style="margin-left: 9px">公告</div>
        <el-input
          :autosize="{ minRows: 2, maxRows: 20 }"
          type="textarea"
          placeholder="请输入内容"
          v-model="ruleForm.live_bbs"
          maxlength="225"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item label="直播类型">
        <div slot="label" style="margin-left: 9px">直播模式</div>
        <div class="course_type">
          <div class="flex-center"></div>
          <el-radio-group
            v-model="ruleForm.screen_type"
            style="display:flex;flex-direction: column;min-height: 50px;justify-content: space-between;"
            :disabled="$route.params.id != 'new'"
          >
            <el-radio label="1">
              横屏直播 <span :style="`${$route.params.id != 'new'?'':'color: #666666;'}font-size: 12px;padding-left:20px;`">视频比例为16:9，适用于企业/PPT宣讲等场景</span>
            </el-radio>
            <el-radio label="2">
              竖屏直播 <span :style="`${$route.params.id != 'new'?'':'color: #666666;'}font-size: 12px;padding-left:20px;`">视频比例为9:16，适用于泛娱乐化教学、带货等场景，建议使用obs直播</span>
            </el-radio>
          </el-radio-group>
          <!-- <div class="smalltip" style="color: #FF3535;margin-top:14px;padding-left:30px;">
            提示：竖屏直播模式中，学生端直播间不支持聊天室内容分离
          </div> -->
          <div class="example" style="position: absolute;right: 26px;top:25px;">
            
            <el-popover placement="bottom-end" trigger="hover" width="auto">
              <div class="previewImages">
                <img src="@/assets/img/1.4.41/img_slt@2x.png" style="width: 100%;"/>
                <div style="height: 40px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0 80px;">
                  <span>横屏直播</span>
                  <span>竖屏直播</span>
                </div>
              </div>
              <span slot="reference" style="color: #1DA9A2;font-size: 12px;cursor: pointer;">查看示例</span>
            </el-popover>
            <span style="color: #1DA9A2;font-size: 12px;cursor: pointer;margin-left:40px;" @click="open(
              'https://www.yuque.com/docs/share/f568ec57-9ea3-4ef7-b07a-d871f6a74fb7?#'
            )">查看教程</span>
          </div>
        </div>
      </el-form-item>

      <!--直播分组-->
      <el-form-item label="直播分组">
        <div slot="label" style="margin-left: 9px">直播分组</div>
        <div style="display: flex;">
          <YSelect
            style="width:240px;"
            v-model="ruleForm.group_ids"
            :hasAll="false"
            :multiple="true"
            :options="options2" 
            :loading="loading"
            :isFinished="isFinished2"
            :placeholder="'请选择直播分组'"
            @visibleChange="visibleChange" 
            @loadMore="loadMore" 
            @remoteMethod="remoteMethod" 
            @change="change"
          ></YSelect>
          <span class="new-add-sort cp ml20" @click.stop="toggleShowAddLive" style="color: rgb(29, 169, 162);">
            新建分组
          </span>
        </div>
      </el-form-item>

      <el-form-item label="直播类型" v-if="fatherPath == '/publicClass'">
        <div slot="label" style="margin-left: 9px">直播类型</div>
        <div class="course_type">
          <div class="flex-center"></div>
          <el-radio-group
            v-model="ruleForm.live_mode"
            :disabled="ruleForm.status == 1"
          >
            <el-radio label="2">
              快直播
              <!-- <helpIcon
                style="margin-left: 6px; margin-bottom: 5px"
                content="快直播使用OBS推流时，需要在OBS设置面板设置对应属性才会生效"
              ></helpIcon> -->
            </el-radio>
            <el-radio label="1">标准直播</el-radio>
          </el-radio-group>
          <!-- <el-button
            style="margin-left: 72px"
            type="text"
            @click="
              $store.dispatch(
                'open',
                'https://www.yuque.com/dingdingkaike/doc/qv3v6o'
              )
            "
          >
            查看教程
          </el-button> -->
          <div class="smalltip">
            {{
              ruleForm.live_mode == 1
                ? '提示：小程序、PC浏览器端延迟3-5秒，h5端延迟10~25秒。流量费用单价低'
                : '提示：延迟1秒以内，对手机系统版本、微信版本要求比较高。流量费用单价高'
            }}
          </div>
        </div>
      </el-form-item>

      <el-form-item label="观看条件">
        <div slot="label" style="margin-left: 9px">观看条件</div>
        <div class="course_type">
          <el-radio-group
            v-model="ruleForm.play_type"
            v-if="$route.params.id !== 'new'"
          >
            <el-radio label="1">免费</el-radio>
            <el-radio label="2">密码</el-radio>
            <el-radio label="3">付费（大于100元可开启分期支付）</el-radio>
          </el-radio-group>
          <el-radio-group
            v-else
            v-model="ruleForm.play_type"
            @change="radiochange"
          >
            <el-radio label="1">免费</el-radio>
            <el-radio label="2">密码</el-radio>
            <el-radio label="3">付费（大于100元可开启分期支付）</el-radio>
          </el-radio-group>
          <div>
            <template v-if="ruleForm.play_type == 2">
              <div class="flex-center">
                <div class="name">
                  <span class="redcolor">*</span>
                  观看密码
                </div>
                <el-input
                  maxlength="8"
                  style="width: 300px"
                  v-model="ruleForm.play_pwd"
                  oninput="value=value.replace(/[^\w\.\/]/ig,'')"
                  placeholder="请输入8位数以内的数字或字母密码"
                  @blur="salaryChange4"
                ></el-input>
              </div>
            </template>
            <template v-if="ruleForm.play_type == 3">
              <div class="flex-center mb10">
                <div class="name">
                  <span class="redcolor">*</span>
                  价格
                </div>
                <input
                  class="placeholder333"
                  @input="toNumber"
                  :value="ruleForm.play_price"
                  style="
                    height: 40px;
                    width: 300px;
                    border: 1px solid #dcdfe6;
                    padding-left: 10px;
                  "
                  placeholder="请输入价格"
                  ref="price"
                  @blur="getCostPriceValue2"
                />
              </div>
            </template>
            <template v-if="ruleForm.play_type == 3">
              <div class="flex-center">
                <div class="name">划线价</div>
                <input
                  ref="costprice"
                  @input="toNumber"
                  :value="ruleForm.cost_price"
                  style="
                    height: 40px;
                    width: 300px;
                    border: 1px solid #dcdfe6;
                    padding-left: 10px;
                  "
                  placeholder="请输入划线价，选填"
                  @blur="getCostPriceValue"
                />
              </div>
            </template>
            <huabeiIssue
              style="padding: 0px 0 20px 105px"
              v-if="ruleForm.play_type == 3"
              :price="ruleForm.play_price"
              :is_installment_3.sync="ruleForm.is_installment_3"
              :is_installment_6.sync="ruleForm.is_installment_6"
              :is_installment_12.sync="ruleForm.is_installment_12"
            ></huabeiIssue>
          </div>
        </div>
      </el-form-item>

      <!-- <el-form-item label="在线人数显示">
        <div slot="label" style="margin-left: 9px">在线人数显示</div>
        <div class="course_type">
          <el-radio-group
            v-model="ruleForm.is_show_online"
            v-if="this.$route.params.id !== 'new'"
          >
            <el-radio label="1">真实人数</el-radio>
            <el-radio label="2">虚拟人数</el-radio>
            <el-radio label="3">不显示</el-radio>
          </el-radio-group>
          <el-radio-group
            @change="is_show_onlineChange"
            v-else
            v-model="ruleForm.is_show_online"
          >
            <el-radio label="1">真实人数</el-radio>
            <el-radio label="2">虚拟人数</el-radio>
            <el-radio label="3">不显示</el-radio>
          </el-radio-group>
          <el-popover placement="bottom-end" trigger="hover" width="650">
            <div style="width: 100%">
              <img
                src="~@ass/img/1.4.2.3/img_zxrssl@2x.png"
                style="width: 100%"
                alt=""
              />
            </div>
            <el-button type="text" slot="reference" class="fr">
              查看示例
            </el-button>
          </el-popover>

          <template v-if="ruleForm.is_show_online == 2">
            <div class="flex-center mb20">
              <div class="name">
                自定义人数
                <span class="redcolor">*</span>
              </div>
              <el-input
                maxlength="5"
                v-model="ruleForm.virtual_online"
                oninput="value=value.replace(/[^\d]/g,'')"
                @blur="salaryChange2"
                placeholder="请输入数字"
              ></el-input>
            </div>
          </template>

          <div class="smalltip" v-if="ruleForm.is_show_online != 3">
            提示：真实人数指直播间实时人数，虚拟人数可自定义数字+真实人数
          </div>
        </div>
      </el-form-item> -->

      <!-- <el-form-item label="直播回放">
        <div slot="label" style="margin-left: 9px">直播回放</div>
        <div class="switchselect">
          <div class="top">
            <div class="flex-center">
              <el-switch
                v-model="ruleForm.is_show_playback"
                active-value="1"
                inactive-value="2"
              ></el-switch>
              <div class="switch_font">
                <div class="font1">显示回放模块</div>
                <div class="font2">
                  开启后，学员端显示回放模块，可查看直播生成的回放视频
                </div>
              </div>
            </div>
            <el-popover placement="bottom-end" trigger="hover" width="650">
              <div style="width: 100%">
                <img
                  src="~@ass/img/1.4.2.3/img_hfmksl@2x.png"
                  style="width: 100%"
                  alt=""
                />
              </div>
              <el-button type="text" slot="reference" class="fr">
                查看示例
              </el-button>
            </el-popover>
          </div>
          <div class="top" v-if="ruleForm.is_show_playback == 1">
            <div class="flex-center">
              <el-switch
                v-model="ruleForm.switch3"
                active-value="1"
                inactive-value="2"
              ></el-switch>
              <div class="switch_font">
                <div class="font1">回放聊天室</div>
                <div class="font2">
                  开启后，观看回放时会显示直播间的聊天内容
                </div>
              </div>
            </div>
            <el-button type="text">查看示例</el-button>
          </div>
          <div
            class="top"
            v-if="ruleForm.is_show_playback == 1"
            style="border: 0"
          >
            <div class="flex-center">
              <el-switch
                v-model="ruleForm.switch2"
                active-value="1"
                inactive-value="2"
              ></el-switch>
              <div class="switch_font">
                <div class="font1">回放禁言</div>
                <div class="font2">
                  开启后，观看回放时处于禁言状态，不可发言
                </div>
              </div>
            </div>
            <el-button type="text">查看示例</el-button>
          </div>
        </div>
      </el-form-item> -->
      <el-form-item label="封面" :required="true">
        <div class="course-pic-wrap">
          <img :src="ruleForm.cover_img" alt="" />
          <div class="p-wrap">
            <p class="txt">建议尺寸800*450px，JPG、PNG格式</p>
            <div class="btn-wrap">
              <div class="btnchangePhotoimg" plain>更换封面</div>
              <changePhotoimg
                :size="2"
                @complete="completechangePhotoimg"
                class="changePhotoimg"
              ></changePhotoimg>
            </div>
          </div>
        </div>
      </el-form-item>

      <!--课程详情-->
      <el-form-item label="课程详情">
        <editor class="editor" v-model="ruleForm.details"></editor>
      </el-form-item>
    </el-form>

    <div class="bottomBtn">
      <el-button type=" " size="medium" @click.stop="cancel">取消</el-button>
      <!-- <el-button
        type=" "
        size="medium"
        class="greencolor"
        @click.stop="setting()"
      >
        直播设置
      </el-button> -->
      <el-button
        type="primary"
        :loading="isDisable"
        :disabled="isDisable"
        @click.stop="submitForm()"
        size="medium"
      >
        保存
      </el-button>
    </div>
    <livebroadcast-settings
      v-if="status3"
      :ruleForm="$route.params.id === 'new' ? 0 : ruleForm"
      :settingval="$route.params.id === 'new' ? '' : $route.params.id"
      :type="2"
      @complete="complete2"
      :dialogstatus.sync="status3"
    ></livebroadcast-settings>
    <!-- 新建分组 -->
    <CourseConfirm
      v-if="showAddLive"
      title="新建分类"
      :isShowBtn="true"
      @cancelFn="closeFn"
      @save="saveLive"
    >
      <div class="addLive">
        <span class="mr20">分组名称</span>
        <el-input
          v-model="addLive.group_name"
          type="text"
          size="medium"
          placeholder="请输入内容"
          style="width: 218px"
          maxlength="50"
          show-word-limit
        ></el-input>
      </div>
    </CourseConfirm>
    <CopyPop
      v-if="isShowCopy"
      @closeFn="toggleShowCopy"
      :type="fatherPath=='/publicClass'?1:2"
      :isOpenClassLive="true"
      @copySuccess="copySuccess"
    ></CopyPop>
    
    <!-- 选择直播课 -->
    <networkschoolSelect
      @selectChangenetworkschool="closeGoodsConfirm"
      @singleselectcontentval="singleselectcontentval"
      title="添加直播课"
      v-if="networkschoolSelectStatus"
      :dialogstatus.sync="networkschoolSelectStatus"
      :template="2"
      :goodList="ruleForm.course_info"
      :goodlistType="false" 
      :isLeftMenu="false" 
      :isCourseType="false" 
      :isOverMutipleSelect="true" 
      :isOtherMutipleSelect="true" 
      isDefaultCourseType="1" 
      ></networkschoolSelect>
      <!-- :ids="ids" -->
      <!-- :price_mold="1"  -->
  </div>
</template>

<script>
import livebroadcastSettings from '@/components/livebroadcastSettings'
import changePhotoimg from '@cm/base/changePhotoimg'
import helpIcon from '@/components/helpIcon'
import API from '@/request/api'
import editor from '@cm/editor'
import huabeiIssue from '@cm/huabeiIssue'
import CourseConfirm from '@/components/CourseConfirm'
import CopyPop from '@/components/coursePop/copyPop'
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'

export default {
  name: 'newData',

  components: {
    editor,
    helpIcon,
    changePhotoimg,
    huabeiIssue,
    livebroadcastSettings,
    CourseConfirm,
    CopyPop,
    networkschoolSelect,
  },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        },
      },

      fatherPath: '',

      isDisable: false,

      status2: false,

      status3: false,

      input: '',

      teacherOptions: [],

      ruleForm: {
        name: '',
        cost_price: '',
        teacher_id: '',
        template_switch:'2',
        assistant_ids: [],
        virtual_online: '',
        start_time: '',
        live_mode: '2',
        play_pwd: '',
        play_price: '',
        play_type: '1',
        is_show_online: '1',
        is_show_playback: '1',
        is_installment_3: '2',
        is_installment_6: '2',
        is_installment_12: '2',
        screen_type: '1',
        cover_img:
          'https://f.dingdingkaike.com.cn/openClassLive/img/img_gkkmrfm%402x.png',
        details: '',
        group_ids:[],
        course_ids:[],
        msg_id:'',
      },
      // 复制课程id
      copy_open_class_id:null,
      // 分组
      options2: [],
      selectForm: {
        page: 1,
        num: 15,
        pagesize: 15,
        search: ''
      },
      selectAllAssistant:false,
      loading: false,
      isFinished: false,

      // 新建分组
      showAddLive:false,
      addLive:{},
      isShowCopy: false,

      // 智能脚本
      options3: [],
      selectForm2: {
        type: 3,
        page: 1,
        num: 15,
        pagesize: 15,
        search: ''
      },
      loading2: false,
      isFinished2: false,
      // 选择课程弹窗
      networkschoolSelectStatus: false,
    }
  },

  watch: {
    $route: {
      handler(value) {
        this.fatherPath = '/' + value.path.split('/')[1]
      },
      immediate: true,
    },
  },

  async mounted() {
    await this.getJigouTeacher()
    if (this.$route.params.id !== 'new') {
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '编辑'
      await this.geteditOpenClass()
      let all = {
        page:null,
      }
      this.init(all)
      this.init2(all)
    }
  },
  computed:{
    getCourseName(){
      return this.ruleForm.course_ids.map(v=>v.name).join()
    }
  },
  
  methods: {
    // 添加三分钟
    addMin(val){
      if(val>Number(Date.now()-1000)&&val<Number(Date.now())&&this.fatherPath == '/intelligence'){
        this.ruleForm.start_time = Number(val)+3*60*1000
      }
    },
    // 显示一键复制弹窗
    toggleShowCopy() {
      this.isShowCopy = !this.isShowCopy
    },
    // 复制成功触发
    copySuccess(val) {
      let all = {
        page:null,
      }
      // 复制课程id
      this.copy_open_class_id = val.open_class_id
      this.init(all)
      this.init2(all)
      this.geteditOpenClass(val.open_class_id,this.fatherPath=='/intelligence')
      this.init()
      this.toggleShowCopy()
    },
    getCostPriceValue2() {
      this.ruleForm.play_price = this.$refs.price.value
    },

    getCostPriceValue() {
      this.ruleForm.cost_price = this.$refs.costprice.value
    },

    // 验证金额
    toNumber(event) {
      const target = event.target
      let newValue = target.value.match(/^[0-9]+(?:\.?\d{0,2})?/g)
      newValue = newValue ? newValue[0] : ''
      let formattedValue = newValue
      if (newValue) {
        if (newValue.indexOf('.') > 7) {
          formattedValue =
            newValue.split('.')[0].slice(0, 7) +
            '.' +
            newValue.split('.')[1].slice(0, 2)
        } else {
          formattedValue = newValue.slice(
            0,
            newValue.indexOf('.') === -1 ? 7 : newValue.indexOf('.') + 3
          )
        }
      }
      target.value = formattedValue
    },

    salaryChange4(e) {
      this.ruleForm.play_pwd = e.target.value
    },

    cancel() {
      this.$router.push(this.fatherPath)
    },

    salaryChange(e) {
      this.ruleForm.play_price = e.target.value
    },

    salaryChange2(e) {
      this.ruleForm.virtual_online = e.target.value
    },

    salaryChange3(e) {
      this.ruleForm.cost_price = e.target.value
    },

    is_show_onlineChange() {
      this.ruleForm.virtual_online = ''
    },

    geteditOpenClass(id,isNew) {
      return new Promise((resolve,reject)=>{
        this.$http({
          url: `/openClass/editOpenClass?open_class_id=${id||this.$route.params.id}`,
          callback: ({ data }) => {
            if(isNew){
              data.is_old = '2'
            }
            this.ruleForm = data
            this.ruleForm = _.assign({}, this.ruleForm, {
              start_time: this.ruleForm.start_time * 1000,
              course_ids:this.ruleForm.course_info,
            })
            console.log(this.ruleForm.course_info);
            // 助教全选
            if(this.teacherOptions.length==0) return
            let teachList = this.teacherOptions.map(v=>v.uid)
            if(_.isEqual(data.assistant_ids.sort((l,r)=>l-r),teachList.sort((l,r)=>l-r))){
              this.selectAllAssistant=true
            }
            resolve()
          },
          error: () => {
            this.$root.prompt('操作失败')
            reject()
          },
        })
      })
    },

    complete2(val) {
      this.ruleForm = _.assign({}, this.ruleForm, { ...val })
    },

    //直播设置
    setting() {
      this.status3 = true
    },

    radiochange() {
      this.ruleForm.play_pwd = ''
      this.ruleForm.play_price = ''
      this.ruleForm.cost_price = ''
    },

    //保存
    submitForm() {
      this.ruleForm = _.assign({}, this.ruleForm, {
        name: this.ruleForm.name == undefined ? '' : this.ruleForm.name.trim(),
        virtual_online:
          this.ruleForm.virtual_online == undefined
            ? ''
            : this.ruleForm.virtual_online.trim(),
        cost_price:
          this.ruleForm.cost_price == undefined
            ? ''
            : this.ruleForm.cost_price.trim(),
        play_pwd:
          this.ruleForm.play_pwd == undefined
            ? ''
            : this.ruleForm.play_pwd.trim(),
        play_price:
          this.ruleForm.play_price == undefined
            ? ''
            : this.ruleForm.play_price.trim(),
      })

      const copyStarTime = this.ruleForm.start_time

      if (!this.ruleForm.name) {
        this.$root.prompt('请输入名称')
        return
      }
      if (!this.ruleForm.teacher_id) {
        this.$root.prompt('请选择直播老师')
        return
      }
      // if (!this.ruleForm.start_time && this.fatherPath == '/publicClass') {
      if (!this.ruleForm.start_time) {
        this.$root.prompt('请选择直播时间')
        return
      }
      if (!this.ruleForm.msg_id&&this.fatherPath == '/intelligence'&&this.ruleForm.is_old!=1) {
        this.$root.prompt('请选择智能脚本')
        return
      }
      if (!this.ruleForm.cover_img) {
        this.$root.prompt('请选择封面')
        return
      }
      if (this.ruleForm.play_type == 2 && !this.ruleForm.play_pwd) {
        this.$root.prompt('请输入观看密码')
        return
      }
      if (this.ruleForm.play_type == 3 && !this.ruleForm.play_price) {
        this.$root.prompt('请输入价格')
        return
      }
      if (this.ruleForm.is_show_online == 2 && !this.ruleForm.virtual_online) {
        this.$root.prompt('请输入虚拟人数')
        return
      }
      if (
        this.ruleForm.play_type == 2 &&
        this.ruleForm.play_pwd &&
        !/^[a-zA-Z0-9]{1,8}$/.test(this.ruleForm.play_pwd)
      ) {
        this.$root.prompt('观看密码格式有误，请输入数字或字母！')
        return
      }
      const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^[1-9]$)|(^\d\.[1-9]{1,2}$)|(^\d\.[0]{1}[1-9]{1}$|(^\d\.[1-9]{1}[0]{1}$)$)/
      if (this.ruleForm.play_type == 3 && !reg.exec(this.ruleForm.play_price)) {
        this.$root.prompt('请输入有效金额')
        return
      }
      if (
        this.ruleForm.play_type == 3 &&
        this.ruleForm.cost_price &&
        !reg.exec(this.ruleForm.cost_price)
      ) {
        this.$root.prompt('请输入有效划线价')
        return
      }

      this.isDisable = true

      this.ruleForm.start_time = this.ruleForm.start_time / 1000

      if (this.fatherPath == '/intelligence') {
        this.ruleForm.live_mode = '3'
      }

      if (this.$route.params.id === 'new') {
        this.$http({
          url: '/openClass/createOpenClass',
          data: Object.assign(this.ruleForm,{
            course_ids:this.ruleForm.course_ids.map(v=>v.course_id),
            copy_open_class_id:this.copy_open_class_id,
          }),
          callback: () => {
            this.$root.prompt({
              type: 'success',
              msg: '保存成功',
            })
            // if (this.fatherPath == '/intelligence') {
            //   this.$router.replace({
            //     path: '/intelligence/intelligenceManager',
            //     query: { open_class_id: data },
            //   })
            // } else {
            //   this.$router.replace({ path: this.fatherPath })
            // }
            this.$router.replace({ path: this.fatherPath })
          },
          error: ({info}) => {
            this.isDisable = false //点击一次时隔两秒后才能再次点击
            this.$root.prompt(info||'操作失败')
          },
        })
      } else {
        this.$http({
          url: '/openClass/editOpenClass',
          data: _.assign({}, this.ruleForm, {
            open_class_id: this.$route.params.id,
            course_ids:this.ruleForm.course_ids.map(v=>v.course_id)
          }),
          callback: () => {
            this.$root.prompt({
              type: 'success',
              msg: '保存成功',
            })

            this.$router.replace({ path: this.fatherPath })
          },

          error: ({info}) => {
            this.isDisable = false //点击一次时隔两秒后才能再次点击
            this.$root.prompt(info||'操作失败')
          },
        })
      }
      this.ruleForm.start_time = copyStarTime
    },

    completechangePhotoimg(val) {
      this.ruleForm.cover_img = this.$options.filters.imageslim(val, 3)
    },

    // 获取机构老师
    getJigouTeacher() {
      const self = this
      return new Promise((resolve,reject)=>{
        self.$http({
          name: API.getJigouTeacher_Api.name,
          url: API.getJigouTeacher_Api.url,
          callback({ data: { teacherInfo } }) {
            self.teacherOptions = teacherInfo
            resolve()
          },
          error(error) {
            if (error.code == 204) {
              self.teacherOptions = []
            }
            reject(error)
          },
        })
      })
    },
    // 打开外部链接
    open(url) {
      this.$store.dispatch('open', url)
    },

    // 分组
    async init(val) {
      this.loading = true
      await this.$http({
        url: '/liveGroup/getGroupList',
        data: Object.assign(this.selectForm2,val),
        callback: ({data,count}) => {
          const list = data.map(v=>Object.assign(v,{
            id:v.group_id,
            name:v.group_name,
          }))
          // 去重
          let list2 = _.uniqBy(list.concat(this.options2),'group_id');
          this.options2 = _.sortBy(list2,v=>-Number(v.group_id))
          this.loading = false
          if(this.options2.length==count) {
            this.isFinished=true
          }
        },
        error: ({ info }) => {
          this.$root.prompt({
            msg: info,
          })
        },
      })
    },
    change(value) {
      console.log(value);
    },
    visibleChange(status) {
      if(!status){
        this.selectForm.search = ''
        // this.options2=[]
      } else {
        this.init()
      }
    },
    loadMore(page) {
      this.selectForm.page = page
      this.init()
    },
    remoteMethod(query, page) {
      this.selectForm.page = page
      this.selectForm.search = query
      this.options2=[]
      this.init()
    },

    // 智能脚本
    async init2(val) {
      this.loading2 = true
      await this.$http({
        url: '/chatMessage/chatMsgList',
        data: Object.assign(this.selectForm2,val),
        callback: ({data,count}) => {
          const list = data.map(v=>Object.assign(v,{
            id:v.msg_id,
            name:v.name,
          }))
          // 去重
          let list2 = _.uniqBy(list.concat(this.options3),'msg_id');
          this.options3 = _.sortBy(list2,v=>-Number(v.msg_id))
          this.loading2 = false
          if(this.options3.length==count) {
            this.isFinished2=true
          }
        },
        error: ({ info }) => {
          this.$root.prompt({
            msg: info,
          })
        },
      })
    },
    change2(value) {
      console.log(value);
    },
    visibleChange2(status) {
      if(!status){
        this.selectForm2.search = ''
        // this.options2=[]
      } else {
        this.init2()
      }
    },
    loadMore2(page) {
      this.selectForm2.page = page
      this.init2()
    },
    remoteMethod2(query, page) {
      this.selectForm2.page = page
      this.selectForm2.search = query
      this.options2=[]
      this.init2()
    },

    toggleShowAddLive(){
      this.showAddLive=true
    },

    closeFn(){
      this.addLive = {}
      this.showAddLive=false
    },
    saveLive(){
      this.$http({
        url: '/liveGroup/addGroup',
        data: Object.assign(this.addLive,{
          name:this.addLive.group_name,
        }),
        callback: () => {
          this.closeFn()
          this.$root.prompt({
            msg: '新建成功',
            type: 'success',
          })
        },
        error: ({ info }) => {
          this.$root.prompt({
            msg: info,
          })
        },
      })
    },
    selectAll() {
      this.ruleForm.assistant_ids = [];
      if (this.selectAllAssistant) {
        this.teacherOptions.forEach((item) => { this.ruleForm.assistant_ids.push(item.uid )});
      } else {
        this.ruleForm.assistant_ids = [];
      }
    },
    changeAssistant(val) {
      if (val.length === this.teacherOptions.length) {
        this.selectAllAssistant = true;
      } else {
        this.selectAllAssistant = false;
      }
    },
    clickBox(val) {
      let values = this.ruleForm.assistant_ids
      if(values.includes(val)) {
        this.ruleForm.assistant_ids = values.filter(n => n !== val)
      } else {
        values.push(val)
        this.ruleForm.assistant_ids = values
      }
      this.changeAssistant(this.ruleForm.assistant_ids)
    },
    // 关闭选择课程
    closeGoodsConfirm() {
      this.networkschoolSelectStatus = false
    },
    
    // 改变课程列表
    singleselectcontentval(list) {
      this.ruleForm.course_ids.push(...list)
      this.networkschoolSelectStatus = false
    },
    // 移除选择的课程
    confirmCouseselect(index) {
        this.ruleForm.course_ids.splice(index, 1)
    },
  },
}
</script>
<style lang="scss" scoped>
input::-webkit-input-placeholder {
  color: #c0c4cc;
}
#newData {
  background-color: #fff;
  padding: 20px;
  ::v-deep .el-form {
    // width: 871px;
  }

  ::v-deep .el-textarea .el-input__count {
    bottom: 23px;
    height: 4px;
    padding: 0;
  }

  .titleHeader {
    margin-bottom: 40px;
    height: 40px;
    background: #fff1de;
    border-radius: 2px;
    border: 1px solid #f9dbb3;
    display: flex;

    img {
      width: 13px;
      height: 14px;
      margin: 14px;
    }

    p {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #816440;
      line-height: 40px;
    }
  }
  .copyBtn {
    margin: 0px 0 28px;
    border: 1px solid #0aa29b;
    color: #0aa29b;
  }
  .course_type {
    padding: 5px 20px 10px;
    background: #f5f5f5;

    .name {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      width: 107px;
      //   margin-right: 65px;
    }
  }

  .switchselect {
    padding: 0 20px;
    background: #f5f5f5;
    .switch_font {
      margin-left: 30px;
      .font1 {
        font-size: 14px;
        color: #333333;
        line-height: 14px;
      }
      .font2 {
        margin-top: 14px;
        font-size: 12px;
        color: #666666;
        line-height: 12px;
      }
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid #e2e2e2;
    }
  }

  .smalltip {
    font-size: 12px;
    color: #666666;
    line-height: 12px;
    margin-top: 5px;
  }

  .course-pic-wrap {
    display: flex;
    img {
      width: 220px;
      height: 124px;
    }
  }

  .p-wrap {
    margin-left: 20px;
    .txt {
      margin-bottom: 22px;
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      line-height: 16px;
    }

    .btn-wrap {
      position: relative;
      .changePhotoimg {
        position: absolute;
        height: 40px !important;
        position: absolute;
        top: 0;
      }
      .btnchangePhotoimg {
        border: 1px #d3d4d6 solid;
        position: absolute;
        top: 0;
        padding: 0 20px;
        background-color: #f4f4f5;
        font-size: 14px;
        color: #909399;
        border-radius: 4px;
        cursor: pointer;
      }
      .warning {
        margin-left: 10px;
        font-size: 12px;
        color: rgba(255, 53, 53, 1);
        line-height: 16px;
      }
    }
  }

  ::v-deep .bottomBtn {
    margin-left: 224px;

    .el-button {
      width: 86px;
      padding: 10px 12px;
    }

    .greencolor {
      color: #0aa29b !important;
      border: 1px solid #0aa29b !important;
    }
  }
  .addLive {
    width: 640px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 60px 0;
    ::v-deep .el-input__inner {
      padding-right: 50px;
    }
  }
  .add_button {
      width: 120px;
      height: 42px;
      line-height: 42px;
      border-radius: 4px;
      border: 1px solid rgba(10, 162, 155, 1);
      cursor: pointer;
      color: rgba(10, 162, 155, 1);
      font-size: 14px;
      text-align: center;
      display: inline-block;
    }
    
    .select-contain {
      .list {
        .tu {
          width: 40px;
          height: 23px;
          background-color: #f5f5f5;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: calc(100% - 50px);
          font-size: 13px;
          color: #333333;
        }
      }
    }
    ::v-deep .selectDialogBox {
      .rightContent {
        width: 100%;
      }
    }
}
.previewImages {
  width:560px;
}
</style>
<style>
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  display: none;
}
</style>